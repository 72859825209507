import React, { Component } from 'react';

class top extends Component {
	render(){
		return(
			<React.Fragment>
				<img src={require('assets/img/berita/Berita_Shape.png')} alt="" className="img-fluid"/>
				<div className="head_title">
					<p>Berita</p>
				</div>
			</React.Fragment>
		)
	}
}

export default top;
