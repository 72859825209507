import React, { Component } from 'react';
import SEO2 from 'components/SEO/SEO2';
import { Link, graphql } from 'gatsby';
import Layout from 'layout';
import Top from './top'
import config from "data/SiteConfig";


class Post extends Component {

	generateRelated = () => {
		const { other } = this.props.data;
		return other.edges.map((data, x) =>
			<Link to={`/post/${data.node.slug}`} key={x} className="list_news__box">
				<div className="img">
					<img src={data.node.banner} alt="" className="img-fluid" />
				</div>
				<div>
					<div className="heads">{data.node.title}</div>
					<div className="date_tag">
						<span className="date">{data.node.date}</span>
						<span className="tag">{data.node.type.data.name}</span>
					</div>
				</div>
			</Link>
		)
	}

	render() {
		const { data } = this.props;
		return (
			<Layout>
				<SEO2 
					postSEO={{
						title: data.post.seo_title,
						description: data.post.seo_description,
						image: data.post.seo_image,
						url: config.siteUrl + "post/" + data.post.slug,
					}}
				/>
				<div className="news-container" >
					<div className="top"></div>
					<div className="news_layout">
						<div className="box_news">
							<Top />
							<div id="Post" className="box">
								<div className="flag">
									Anda berada di :
                					<span>Home</span>
									{`>>`}
									<span>News</span>
									{`>>`}
									<span>{data.post.type.data.name}</span>
								</div>
								<div className="post_detail">
									<div className="headline">
										<div className="title">
											<p>{data.post.title}</p>
										</div>
										<div className="date_tag">
											<span className="date">
												{data.post.date}
											</span>
											<span className="tag">
												{data.post.type.data.name}
											</span>
										</div>
									</div>
									<div className="details">
										<img src={data.post.banner} alt="" className="img-fluid" />
										<div dangerouslySetInnerHTML={{ __html: data.post.content }}></div>
									</div>
								</div>
								<div className="related_news">
									<div className="title">
										<span>Berita Terkait</span>
									</div>
									<div className="list_news">
										{this.generateRelated()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default Post;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
fragment post_field on DirectusPost {
	directusId
	title
	content
	date(formatString: "DD/MM/YY")
	type {data {id name}}
	banner
	slug
  }
  
  fragment seo_field on DirectusPost {
	seo_title
	seo_description
	seo_image
  }
  
  query PostQuery($id: Int!, $type: Int!)
  {
	post: directusPost(
	  directusId: {
		eq: $id
	  }
	){
	  ...seo_field
	  ...post_field
	}
	other: allDirectusPost(
	  filter: {
		type: {
		  data: {
			id: {eq: $type}
		  }
		}
		directusId: {ne: $id}
	  }
	  limit: 3
	  sort: {fields: date, order: DESC}
	) {
	  edges {
		node {        
		  ...post_field
		}
	  }
	}
  }
`;